export const MasterAppointmentSettingsPageConstant = {
    masterAppointmentSettings: 'Reception_Management_Master_Appointment_Settings.masterappointmentsettings',
    appointment: 'Reception_Management_Master_Appointment_Settings.appointment',
    status: 'Reception_Management_Master_Appointment_Settings.status',
    image: 'Reception_Management_Master_Appointment_Settings.image',
    description: 'Reception_Management_Master_Appointment_Settings.description',
    day: 'Reception_Management_Master_Appointment_Settings.day',
    parentName: 'Reception_Management_Master_Appointment_Settings.parentname',
    code: 'Reception_Management_Master_Appointment_Settings.code',
    shape: 'Reception_Management_Master_Appointment_Settings.shape',
    isActive: 'Reception_Management_Master_Appointment_Settings.isactive',
    treeList: 'Reception_Management_Master_Appointment_Settings.treelist',
    info: 'Reception_Management_Master_Appointment_Settings.info',
    uploadIcon: 'Reception_Management_Master_Appointment_Settings.uploadicon',
    edit: 'Reception_Management_Master_Appointment_Settings.edit',
    delete: 'Reception_Management_Master_Appointment_Settings.delete',
    treeViewer: 'Reception_Management_Master_Appointment_Settings.treeviewer',
    treeViewerAdd: 'Reception_Management_Master_Appointment_Settings.treevieweradd',
    treeViewerDelete: 'Reception_Management_Master_Appointment_Settings.treeviewerdelete',
    treeViewerEdit: 'Reception_Management_Master_Appointment_Settings.treevieweredit',
    treeViewerActions: 'Reception_Management_Master_Appointment_Settings.treevieweractions',
    search: 'Reception_Management_Master_Appointment_Settings.search',
    reset: 'Reception_Management_Master_Appointment_Settings.reset',
    selectLanguage: 'Reception_Management_Master_Appointment_Settings.selectlanguage',
    appointmentParent: 'Reception_Management_Master_Appointment_Settings.appointmentparent',
    history: 'Reception_Management_Master_Appointment_Settings.history',
    sunday: 'Reception_Management_Master_Appointment_Settings.sunday',
    monday: 'Reception_Management_Master_Appointment_Settings.monday',
    tuesday: 'Reception_Management_Master_Appointment_Settings.tuesday',
    wednesday: 'Reception_Management_Master_Appointment_Settings.wednesday',
    thursday: 'Reception_Management_Master_Appointment_Settings.thursday',
    friday: 'Reception_Management_Master_Appointment_Settings.friday',
    saturday: 'Reception_Management_Master_Appointment_Settings.saturday',
    activationFrom: 'Reception_Management_Master_Appointment_Settings.activationfrom',
    activationTo: 'Reception_Management_Master_Appointment_Settings.activationto',
    activation: 'Reception_Management_Master_Appointment_Settings.activation',
    activated: 'Reception_Management_Master_Appointment_Settings.activated',
    workDays: 'Reception_Management_Master_Appointment_Settings.workdays',
    startDate: 'Reception_Management_Master_Appointment_Settings.startdate',
    endDate: 'Reception_Management_Master_Appointment_Settings.enddate',
    duration: 'Reception_Management_Master_Appointment_Settings.duration',
    doctor: 'Reception_Management_Master_Appointment_Settings.doctor',
    cancel: 'Reception_Management_Master_Appointment_Settings.cancel',
    add: 'Reception_Management_Master_Appointment_Settings.add',
    startTime: 'Reception_Management_Master_Appointment_Settings.starttime',
    endTime: 'Reception_Management_Master_Appointment_Settings.endtime',
    open: 'Reception_Management_Master_Appointment_Settings.open',
    off: 'Reception_Management_Master_Appointment_Settings.off',
    save: 'Reception_Management_Master_Appointment_Settings.save',
    addDoctor: 'Reception_Management_Master_Appointment_Settings.adddoctor',
    outsideTimesMessage: 'Reception_Management_Master_Appointment_Settings.outsidetimesmessage',
    durationInMinutes: 'Reception_Management_Master_Appointment_Settings.durationinminutes',
    medicalVisitType: 'Reception_Management_Master_Appointment_Settings.medicalvisittype',
    medicalVisitTypes: 'Reception_Management_Master_Appointment_Settings.medicalvisittypes',
    doctorsAppointments: 'Reception_Management_Master_Appointment_Settings.doctorsappointments',
    days: 'Reception_Management_Master_Appointment_Settings.days',
    addDurations: 'Reception_Management_Master_Appointment_Settings.adddurations',
    messageDelete: 'Reception_Management_Master_Appointment_Settings.messagedelete',
    open24Hours: 'Reception_Management_Master_Appointment_Settings.open24hours',
    messageChangeIsOpen24: 'Reception_Management_Master_Appointment_Settings.messagechangeisopen24',
    addDoctorAppointments: 'Reception_Management_Master_Appointment_Settings.adddoctorappointments',
    editDoctorAppointments: 'Reception_Management_Master_Appointment_Settings.editdoctorappointments',
    editDurations: 'Reception_Management_Master_Appointment_Settings.editdurations',
    view: 'Reception_Management_Master_Appointment_Settings.view',
    required: 'Reception_Management_Master_Appointment_Settings.required',
    branch: 'Reception_Management_Master_Appointment_Settings.branche',
    country: 'Reception_Management_Master_Appointment_Settings.country',
    state: 'Reception_Management_Master_Appointment_Settings.state',
    city: 'Reception_Management_Master_Appointment_Settings.city',
    pleaseSelectStartAndEndTime: 'Reception_Management_Master_Appointment_Settings.pleaseselectstartandendtime'
};
