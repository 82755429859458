import React, { Suspense } from 'react';

// MUI Components
import CssBaseline from '@mui/material/CssBaseline';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

// routing
import Routes from 'routes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { QueryClient, QueryClientProvider } from 'react-query';

// ==============================|| APP ||============================== //
import RTL from './RTL';
import I18n from 'I18n';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import useFetchData from 'hooks/query/useFetchData';
import { useEffect } from 'react';

// UI Components
import LoaderCircularProgress from 'ui-component/LoaderCircularProgress';

// Context
import { DataContext } from 'context';

// Constants
import { BRANCH, CENTER, DEFAULT_LANGUAGES, GET_STATIC_DATA, LANGUAGE, PAGE_KEYS, SYSTEM_LANGUAGE } from 'env/server';
import HCErrorBoundary from 'ui-component/HCErrorBoundary';
import { changeLanguage, setBranchInfo, setCenterInfo, setData, setLanguages } from 'features/Static/staticSlice';
import { inRole } from 'env/roles';
import { MASTER_NAME } from 'constant';

const queryClient = new QueryClient({
    refetchOnWindowFocus: false,
    retry: false // no retries
});

const GetData = (props) => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const account = useSelector((state) => state.auth.account);
    const myRoles = useSelector((state) => state.auth.account.roles);

    const { data, refetch } = useFetchData('langs', LANGUAGE.GET_ACTIVE_LANGUAGES, {
        enabled: Boolean(token)
    });
    const { data: systemLanguagesData } = useFetchData('systemLanguage', SYSTEM_LANGUAGE.GET, {
        enabled: Boolean(token)
    });

    const { data: pageKeysData } = useFetchData('pageKeys', PAGE_KEYS.GET);

    const { data: getDefaultLanguages } = useFetchData('getDefaultLanguages', DEFAULT_LANGUAGES.GET);

    const { data: defaultLanguages } = useFetchData('defaultLanguages', DEFAULT_LANGUAGES.GET_ACTIVE_LANGUAGES);

    const { data: staticData } = useFetchData('staticData', GET_STATIC_DATA, {
        enabled: Boolean(token)
    });

    const { data: centersData } = useFetchData('centers' + account?.masterId, CENTER.GET, {
        enabled: Boolean(inRole(myRoles, MASTER_NAME.Admin)) && Boolean(account?.isActive)
    });

    const { data: branchInfoData } = useFetchData('branchInfoData' + account?.masterId, BRANCH.GET + '/?id=' + account?.masterId, {
        enabled: Boolean(account?.masterId) && Boolean(inRole(myRoles, MASTER_NAME.Branch))
    });

    const { data: centerInfoData } = useFetchData('centerInfoData' + account?.masterId, CENTER.GET + '/?id=' + account?.masterId, {
        enabled: Boolean(account?.masterId) && inRole(myRoles, MASTER_NAME.Center)
    });

    const languages = data?.data?.data?.data ?? [];
    const systemLanguages = systemLanguagesData?.data?.data?.data ?? [];
    const pages = pageKeysData?.data?.data?.data ?? [];
    const getDefaultLanguagesList = getDefaultLanguages?.data?.data?.data ?? [];
    const defaultLanguagesList = defaultLanguages?.data?.data?.data ?? [];
    const staticDataList = staticData?.data?.data;
    const branchInfo = branchInfoData?.data?.data?.data ? branchInfoData?.data?.data?.data[0] : undefined;
    const centerInfo = centerInfoData?.data?.data?.data ? centerInfoData?.data?.data?.data[0] : undefined;

    useEffect(() => {
        localStorage.setItem('languages', JSON.stringify(languages));

        dispatch(setLanguages(languages));
        dispatch(setData(staticDataList));
        dispatch(setBranchInfo(branchInfo));
        dispatch(setCenterInfo(account?.center ?? centerInfo));
    }, [dispatch, languages, staticDataList, branchInfo, centerInfo]);

    useEffect(() => {
        if (localStorage.getItem('activeLanguage')) dispatch(changeLanguage(localStorage.getItem('activeLanguage')));
        else {
            localStorage.setItem('activeLanguage', 'en');
        }
    }, [localStorage.getItem('activeLanguage')]);
    return (
        <DataContext.Provider
            value={{
                languages,
                systemLanguages,
                refetch,
                pages,
                getDefaultLanguagesList,
                defaultLanguagesList
            }}
            {...props}
        />
    );
};

const App = () => {
    return (
        <HCErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <GetData>
                    <I18n>
                        <Suspense fallback={<LoaderCircularProgress />}>
                            <StyledEngineProvider injectFirst>
                                <RTL>
                                    <CssBaseline />
                                    <NavigationScroll>
                                        <Routes />
                                    </NavigationScroll>
                                </RTL>
                            </StyledEngineProvider>
                        </Suspense>
                    </I18n>
                </GetData>
            </QueryClientProvider>
        </HCErrorBoundary>
    );
};

export default App;
