import React from 'react';

// Icons
import { IconUser, IconUserCircle, IconUserPlus, IconBriefcase, IconNewSection } from '@tabler/icons';

import { roles } from 'env/roles';

const icons = { IconUserCircle, IconUserPlus, IconUser, IconNewSection, IconBriefcase };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import { ReceptionDepartmentsPageConstant } from 'constant/Translations/reception-management/reception';
import { useTranslation } from 'react-i18next';
import { ReservationTypePageConstant } from 'constant/Translations/reception-management/reception/reservation-type';
import { MedicalVisitDescriptionPageConstant } from 'constant/Translations/reception-management/reception/medical-visit-description';
import { MedicalVisitTypePageConstant } from 'constant/Translations/reception-management/reception/medical-visit-type';
import { AppointmentTypePageConstant } from 'constant/Translations/reception-management/reception/appointment-type';
import { RoomPageConstant } from 'constant/Translations/reception-management/reception/room';
import { MedicalReferralsPageConstant } from 'constant/Translations/reception-management/reception/medical-referrals';
import { PatientAppointmentsPageConstant } from 'constant/Translations/reception-management/patient-appointments';
import { ExternalReferralsPageConstant } from 'constant/Translations/reception-management/reception/external-referrals';
import { EscortsTypePageConstant } from 'constant/Translations/reception-management/reception/escorts-type';
import { RelativesPageConstant } from 'constant/Translations/reception-management/reception/relatives';
import { ExternalPageConstant } from 'constant/Translations/reception-management/reception/external';
const useReceptionDepartment = () => {
    const { t } = useTranslation();
    return {
        id: 'reception-management',
        title: 'Reception Management',
        type: 'group',
        icon: <ReceiptLongRoundedIcon />,
        children: [
            {
                id: 'reception',
                title: 'Reception',
                type: 'group',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                children: [
                    {
                        id: 'reception-departments',
                        title: t(ReceptionDepartmentsPageConstant.receptionDepartments),
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/reception-departments',
                        breadcrumbs: false
                    },
                    {
                        id: 'reservation-type',
                        title: t(ReservationTypePageConstant.reservationType),
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/reservation-type',
                        breadcrumbs: false
                    },
                    {
                        id: 'medical-visit-type',
                        title: t(MedicalVisitTypePageConstant.medicalVisitType),
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/medical-visit-type',
                        breadcrumbs: false
                    },
                    {
                        id: 'medical-visit-description',
                        title: t(MedicalVisitDescriptionPageConstant.medicalVisitDescription),
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/medical-visit-description',
                        breadcrumbs: false
                    },
                    {
                        id: 'appointment-type',
                        title: t(AppointmentTypePageConstant.appointmentType),
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/appointment-type',
                        breadcrumbs: false
                    },
                    {
                        id: 'rooms',
                        title: t(RoomPageConstant.rooms),
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/rooms',
                        breadcrumbs: false
                    },
                    {
                        id: 'medical-referrals',
                        title: t(MedicalReferralsPageConstant.medicalReferrals),
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/medical-referrals',
                        breadcrumbs: false
                    },
                    // {
                    //     id: 'external-referrals',
                    //     title: t(ExternalReferralsPageConstant.externalReferrals),
                    //     type: 'item',
                    //     icon: icons.IconBriefcase,
                    //     role: [roles.Admin],
                    //     url: '/external-referrals',
                    //     breadcrumbs: false
                    // },
                    {
                        id: 'externals',
                        title: t(ExternalPageConstant.externals),
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/externals',
                        breadcrumbs: false
                    },
                    {
                        id: 'escorts-type',
                        title: t(EscortsTypePageConstant.escortsType),
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/escorts-type',
                        breadcrumbs: false
                    },
                    {
                        id: 'relatives',
                        title: t(RelativesPageConstant.relatives),
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/relatives',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'patient-appointments',
                title: t(PatientAppointmentsPageConstant.patientAppointments),
                type: 'item',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                url: '/patients-appointments',
                breadcrumbs: false
            }
        ]
    };
};

export default useReceptionDepartment;
