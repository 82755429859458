import React, { lazy } from 'react';

// Components
import Loadable from 'ui-component/Loadable';

const ReceptionDepartments = Loadable(lazy(() => import('views/reception-management/reception/reception-departments')));
const MedicalVisitType = Loadable(lazy(() => import('views/reception-management/reception/medical-visit-type')));
const MedicalVisitDescription = Loadable(lazy(() => import('views/reception-management/reception/medical-visit-description')));
const AppointmentType = Loadable(lazy(() => import('views/reception-management/reception/appointment-type')));
const ReservationType = Loadable(lazy(() => import('views/reception-management/reception/reservation-type')));
const Rooms = Loadable(lazy(() => import('views/reception-management/reception/rooms')));
const MedicalReferrals = Loadable(lazy(() => import('views/reception-management/reception/medical-referrals')));
// const ExternalReferrals = Loadable(lazy(() => import('views/reception-management/reception/external-referrals')));
const PatientsAppointments = Loadable(lazy(() => import('views/reception-management/patient-appointments')));
const PatientMedicalFile = Loadable(
    lazy(() => import('views/administrative-management/patient-management/patient-records/components/medical-file'))
);

const EscortsType = Loadable(lazy(() => import('views/reception-management/reception/escorts-type')));
const Relatives = Loadable(lazy(() => import('views/reception-management/reception/relatives')));
const Externals = Loadable(lazy(() => import('views/reception-management/reception/externals')));

const routes = [
    {
        path: '/reception-departments',
        element: <ReceptionDepartments />
    },
    {
        path: '/medical-visit-description',
        element: <MedicalVisitDescription />
    },
    {
        path: '/medical-visit-type',
        element: <MedicalVisitType />
    },
    {
        path: '/appointment-type',
        element: <AppointmentType />
    },
    {
        path: '/reservation-type',
        element: <ReservationType />
    },
    {
        path: '/rooms',
        element: <Rooms />
    },
    {
        path: '/medical-referrals',
        element: <MedicalReferrals />
    },
    // {
    //     path: '/external-referrals',
    //     element: <ExternalReferrals />
    // },
    {
        path: '/patients-appointments',
        element: <PatientsAppointments />
    },
    {
        path: '/patient-medical-file',
        element: <PatientMedicalFile />
    },
    {
        path: '/escorts-type',
        element: <EscortsType />
    },
    {
        path: '/relatives',
        element: <Relatives />
    },
    {
        path: '/externals',
        element: <Externals />
    }
];
export default routes;
