import * as Yup from 'yup';
import { LanagugesArrayToValidationSchema } from 'helpers/FromTo';
import { safeJsonParse } from 'helpers';
import i18next from 'i18next';
import { CommonPageConstant } from 'constant/Translations/common';

export const multiLangValidation = () =>
    Yup.object().shape({
        value: Yup.string().required(`required`),
        thesaurus: Yup.object().shape({
            ...LanagugesArrayToValidationSchema(safeJsonParse(localStorage.getItem('languages')))
        })
    });

export const langValidation = () =>
    Yup.object().shape({
        value: Yup.string().required(i18next.t(CommonPageConstant.required))
    });

export const validationInputNumber = (value, min) => {
    if (Number.isNaN(Number(value))) {
        return false;
    }
    if (Number(value) < 0) {
        return false;
    }
    if (Number(value) >= (min ? min : 0)) {
        return true;
    }
};
